import React from 'react';
import { Loader } from '../loaders/loader.jsx';

import SampleFlexible from '../flexible/sample-flexible';

/**
 * Déclaration de la corresspondance nom reçu / nom du composant
 * 
 */
const DynamicComponents = {
	sampleflexible:		SampleFlexible,
};

/**
 * ## Generation des composants dynamiquement suivant JSON 
 * @param {object} block 
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
const ComponentByJSON = (block) => {
	if (typeof DynamicComponents[block.component] !== 'undefined') {
		return React.createElement(DynamicComponents[block.component], {
			key: block._uid,
			...block.datas
		});
	}
	// component doesn't exist yet
	return React.createElement(() => <Loader />, { key: block._uid });
};
export default ComponentByJSON;