import React  from "react";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

const Reviews = () => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const pagination = React.useRef(null)


	return(

    <div className="m-reviews__section swiper">
      <div className="layout_inner">
        <h2 className="m-reviews__main-title section-title is-white">Ils ont testé et approuvé<br /> les pompes à chaleur Saunier Duval</h2>

        <div className="m-reviews__swiper-wrapper">
          <Swiper
            slidesPerView={1}
            allowTouchMove={1}
            loop={1}
            spaceBetween={20}
            speed={800}
            // navigation={{
            //   prevEl: navigationPrevRef.current,
            //   nextEl: navigationNextRef.current,
            // }}
            // pagination={{
            //   el: pagination.current,
            //   clickable: true
            // }}
            breakpoints={{
              768: {
                allowTouchMove: false,
                slidesPerView: 2
              },
            }}
          >
            <SwiperSlide>
              <div className="row">
                <div className="m-reviews__content">
                  <h3 className="m-reviews__title">Une fabrication française</h3>
                  <p>Je choisis pour mes clients les pompes à chaleur Saunier Duval car elles sont fabriquées en France avec des matériaux de très bonne qualité et qui durent dans le temps. Je trouve aussi que l’esthétique de l’unité extérieure est très réussie et qu’elle s’intègre facilement chez mes clients.</p>
                  <div className="m-reviews__author-wrapper">
                    <p className="m-reviews__author">Jérôme Noël</p>
                    <p className="m-reviews__author"><small>Installateur Villeneuve Chauffage (59)</small></p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="row">
                <div className="m-reviews__content">
                  <h3 className="m-reviews__title">Une pompe à chaleur silencieuse</h3>
                  <p>Je voulais mettre une pompe à chaleur en remplacement de ma chaudière fioul. J’avais juste un doute : ma maison étant mitoyenne, je ne voulais pas avoir des problèmes de bruit avec mes voisins. J’ai été vite rassurée : la pompe à chaleur Saunier Duval proposée par mon installateur ne fait pas plus de bruit qu’un réfrigérateur...</p>
                  <div className="m-reviews__author-wrapper">
                    <p className="m-reviews__author">Josiane</p>
                    <p className="m-reviews__author"><small>propriétaire d'une maison à Nantes (44)</small></p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>

          <div className="swiper-pagination m-reviews__pagination" ref={pagination}></div>

          {/* <div className="swiper-button-prev m-reviews__nav-button" ref={navigationPrevRef}></div>
          <div className="swiper-button-next m-reviews__nav-button" ref={navigationNextRef}></div> */}
        </div>
        
      </div>
    </div>

	)
}
export default Reviews;