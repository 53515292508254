import React  from "react";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);


const BestSellers = () => {
  const pagination = React.useRef(null)

  // Images
  const products = [
    {
      url: require('../../assets/images/products/genia-air-max.jpg'),
      alt: 'Les PAC les plus silencieuses du marché',
      link: "https://www.saunierduval.fr/particulier/produits-et-documentations/geniaair-max-83456.html",
    },
    {
      url: require('../../assets/images/products/genia-set-split.jpg'),
      alt: 'Fabrication 100% française',
      link: "https://www.saunierduval.fr/particulier/produits-et-documentations/geniaset-split-45440.html",
    },
    {
      url: require('../../assets/images/products/genia-air-split.jpg'),
      alt: 'Les PAC les plus silencieuses du marché',
      link: "https://www.saunierduval.fr/particulier/produits-et-documentations/geniaair-split-51392.html",
    },
  ]

	return(

    <div className="m-best-sellers__section">
      <div className="layout">
        <h2 className="section-title">Nos meilleures ventes</h2>

        <div className="m-best-sellers__list-wrapper m-thumbnail-slider__swiper-wrapper swiper">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            allowTouchMove={1}
            loop={1}
            speed={800}
            pagination={{
              el: pagination.current,
              clickable: true
            }}
            breakpoints={{
              992: {
                spaceBetween: 50,
                slidesPerView: 3,
                allowTouchMove: false,
              },
              768: {
                spaceBetween: 20,
                slidesPerView: 3,
                allowTouchMove: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="m-thumbnail">
                <div className="m-thumbnail__inner has-link shadow">
                  <div className="m-thumbnail__image-wrapper">
                    <a href={ products[0].link } className="m-thumbnail__block-link" target="_blank">
                      <span className="m-thumbnail__label">La + puissante</span>
                      <img className="m-thumbnail__image" src={ products[0].url } alt={ products[0].alt } />
                    </a>
                  </div>
                  <div className="m-thumbnail__content">
                    <a href={ products[0].link } className="m-thumbnail__block-link" target="_blank">
                      <span className="m-thumbnail__title">GeniaAir Max</span>
                    </a>
                    <ul>
                      <li>Pompe à chaleur silencieuse et discrète</li>
                      <li>Réduit votre impact sur l'environnement</li>
                      <li>Dimensions compactes</li>
                      <li>Pilotage à distance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail">
                <div className="m-thumbnail__inner has-link shadow">
                  <div className="m-thumbnail__image-wrapper">
                    <a href={ products[1].link } className="m-thumbnail__block-link" target="_blank">
                      <span className="m-thumbnail__label">La + économique</span>
                      <img className="m-thumbnail__image" src={ products[1].url } alt={ products[1].alt } />
                    </a>
                  </div>
                  <div className="m-thumbnail__content">
                    <a href={ products[1].link } className="m-thumbnail__block-link" target="_blank">
                      <span className="m-thumbnail__title">GeniaSet Split</span>
                    </a>
                    <ul>
                      <li>Pompe à chaleur air-eau bi-bloc</li>
                      <li>Chauffage, eau chaude et rafraîchissement</li>
                      <li>Jusqu’à 8 fois plus silencieuse qu'une pompe à chaleur standard du marché</li>
                      <li>Pilotage à distance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail">
                <div className="m-thumbnail__inner has-link shadow">
                  <div className="m-thumbnail__image-wrapper">
                    <a href={ products[2].link } className="m-thumbnail__block-link" target="_blank">
                      <span className="m-thumbnail__label">La + flexible</span>
                      <img className="m-thumbnail__image" src={ products[2].url } alt={ products[2].alt } />
                    </a>
                  </div>
                  <div className="m-thumbnail__content">
                    <a href={ products[2].link } className="m-thumbnail__block-link" target="_blank">
                      <span className="m-thumbnail__title">GeniaAir Split</span>
                    </a>
                    <ul>
                      <li>Solution modulaire</li>
                      <li>Chauffage et rafraîchissement</li>
                      <li>Source d'énergie gratuite : calories de l'air extérieur</li>
                      <li>Ultra silencieuse</li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>

          <div className="swiper-pagination m-reviews__pagination" ref={pagination}></div>
        </div>


        <div className="text-center mb-20">
          <a href="https://www.saunierduval.fr/particulier/produits-et-documentations/pompe-a-chaleur/" target="_blank" className="button">Tous nos produits</a>
        </div>
        <div className="text-center">
          <a href="https://www.saunierduval.fr/particulier/aide-conseils/guides-pompes-a-chaleur/quelle-pompe-a-chaleur-chosir/" target="_blank" className="button border">Trouver le produit le plus adapté</a>
        </div>

      </div>
    </div>

	)
}
export default BestSellers;