import React  from "react";

const SampleFlexible = ( {image, title, text} ) => {

    const renderMarkup = (markup) => {
		return { __html: markup };
	};

	return(
        <div className="block">
            <div className="block_head">
                {title && <h2 className="block_head-title" dangerouslySetInnerHTML={renderMarkup(text)}></h2>}
                {image && <img className="block_head-img" src={ image.url } alt={ image.alt } />}
                {text && <p>{ text }</p>}
            </div>
        </div>
	)
}
export default SampleFlexible;