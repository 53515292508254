import React  from "react";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

// Images

const ThumbnailSlider = () => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const pagination = React.useRef(null)

  const thumbnails = [
    {
      url: require('../../assets/images/stereotypes/idee-recue-1.png'),
      alt: 'Il paraît que les pompes à chaleur ne sont pas efficaces'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-2.png'),
      alt: 'Il paraît que les pompes à chaleur sont coûteuses'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-3.png'),
      alt: 'Il paraît que les pompes à chaleur ne fonctionnent qu’avec un plancher chauffant'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-4.png'),
      alt: 'Il paraît que les pompes à chaleur ne peuvent être utilisées que pour le chauffage'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-5.png'),
      alt: 'Il paraît que les pompes à chaleur ne conviennent qu’aux logements neufs'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-6.png'),
      alt: 'Il paraît que les pompes à chaleur prennent beaucoup de place'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-7.png'),
      alt: 'Il paraît que les pompes à chaleur sont bruyantes'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-8.png'),
      alt: 'Il paraît que les pompes à chaleur fonctionnent moins bien en hiver'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-9.png'),
      alt: 'Il paraît que la technologie des pompes à chaleur n’est pas mature'
    },
    {
      url: require('../../assets/images/stereotypes/idee-recue-10.png'),
      alt: 'Il paraît que les pompes à chaleur ne sont pas durables'
    },
  ]

	return(

    <div className="m-thumbnail-slider__section swiper">
      <div className="layout_inner">
        <div className="m-thumbnail-slider__header">
          <h2 className="m-thumbnail-slider__main-title section-title">Halte aux idées reçues</h2>
          <p>Coûteuses, pas efficaces, pas compatibles...<br />Il y a beaucoup d’idées reçues sur les pompes à chaleur. Ici, on vous dit tout !</p>
        </div>

        <div className="m-reviews__swiper-wrapper m-thumbnail-slider__swiper-wrapper">
          <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={50}
            allowTouchMove={1}
            loop={1}
            speed={800}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            pagination={{
              el: pagination.current,
              clickable: true
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
                allowTouchMove: false,
                slidesPerGroup: 2,
              },
            }}
          >
            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[0].url } alt={ thumbnails[0].alt } />
                  <div className="m-thumbnail__content">
                    <p>Les pompes à chaleur réunissent toutes les qualités d’un bon partenaire de vie. Elles vous apportent de la chaleur rapidement dans tout le logement, de la stabilité de température dans toutes les pièces et du confort en toute saison (elles peuvent fonctionner sans problème jusqu’à -25° C extérieur en fonction des modèles).</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[1].url } alt={ thumbnails[1].alt } />
                  <div className="m-thumbnail__content">
                    <p>Le coût initial d’une pompe à chaleur peut être plus élevé que celui d’autres systèmes de chauffage, cependant le prix est amorti grâce à des économies allant jusqu’à 50 % sur votre facture de chauffage. De plus, de nombreuses aides financières existent pour financer son installation.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[2].url } alt={ thumbnails[2].alt } />
                  <div className="m-thumbnail__content">
                    <p>Même si associées à un plancher chauffant elles forment un duo idéal, les pompes à chaleur Saunier Duval sont compatibles avec tous types d’émetteurs, y compris les radiateurs haute température. Notre devise : chaque logement devrait être bien dans ses pompes.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[3].url } alt={ thumbnails[3].alt } />
                  <div className="m-thumbnail__content">
                    <p>L’utilisation des pompes à chaleur pour le chauffage est avérée. Véritable couteau suisse, elles sont aussi particulièrement performantes pour chauffer votre eau chaude sanitaire et rafraîchir votre habitation.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[4].url } alt={ thumbnails[4].alt } />
                  <div className="m-thumbnail__content">
                    <p>Les pompes à chaleur sont très utilisées dans la construction de bâtiments neufs en raison de leur efficacité énergétique et leur performance. Mais elles sont tout aussi adaptées en rénovation, avec des modèles spécifiques qui vous permettent de ne pas changer vos radiateurs !</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[5].url } alt={ thumbnails[5].alt } />
                  <div className="m-thumbnail__content">
                    <p>Les nouvelles générations de pompes à chaleur sont conçues pour économiser l’espace, comme notre pompe à chaleur GeniaSet Split : son unité intérieure et son ballon d'eau chaude sanitaire intégré ne prennent pas plus de place qu'un réfrigérateur de <span className="nowrap">60 cm</span> de large !</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[6].url } alt={ thumbnails[6].alt } />
                  <div className="m-thumbnail__content">
                    <p>Silence, ça tourne ! Les pompes à chaleur Saunier Duval sont jusqu'à 8 fois plus silencieuses que les produits standards du marché.<br /> Par exemple, la Genia Air Split 5 est aussi discrète qu’un réfrigérateur récent avec une pression acoustique de seulement 32 dB(A) à une distance de 5 mètres.<br />L’efficacité ne doit pas être en dépit du confort.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[7].url } alt={ thumbnails[7].alt } />
                  <div className="m-thumbnail__content">
                    <p>Les pompes à chaleur aérothermiques Saunier Duval sont fiables toute l’année, même en cas de températures extrêmes. Nos unités extérieures de pompe à chaleur air-eau peuvent fonctionner jusqu’à -25° C !</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[8].url } alt={ thumbnails[8].alt } />
                  <div className="m-thumbnail__content">
                    <p>Saviez-vous qu’elles existent déjà depuis plus de 40 ans ? Durant toutes ces années, les pompes à chaleur Saunier Duval n’ont cessé d’évoluer pour toujours plus de fiabilité et de performance. Sans compter notre réseau de professionnels formés à leur installation et leur entretien.</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="m-thumbnail m-thumbnail-slider">
                <div className="m-thumbnail__inner shadow">
                  <img className="m-thumbnail-slider__image" src={ thumbnails[9].url } alt={ thumbnails[9].alt } />
                  <div className="m-thumbnail__content">
                    <p>En plus d’être durables dans leur longévité avec une durée de vie moyenne de 15/20 ans, les pompes à chaleur Saunier Duval sont durables écologiquement.<br /> En consommant peu et en utilisant une énergie renouvelable, elles sont particulièrement adaptées au défi climatique : une pompe à chaleur permet de réduire les émissions de CO2 jusqu’à 80 % par rapport à une chaudière fioul !</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>

          <div className="swiper-pagination m-reviews__pagination" ref={pagination}></div>

          <div className="swiper-button-prev m-reviews__nav-button" ref={navigationPrevRef}></div>
          <div className="swiper-button-next m-reviews__nav-button" ref={navigationNextRef}></div>
        </div>

        <div className="text-center">
          <a href="https://www.saunierduval.fr/particulier/aide-conseils/guides-pompes-a-chaleur/" target="_blank" className="button">Tous nos conseils sur les PAC</a>
        </div>
        
      </div>
    </div>

	)
}
export default ThumbnailSlider;