import './Styles/App.scss';
import React, { useEffect } from 'react';
import ScrollToTop from './Components/ScrollToTop';
import { BrowserRouter as  Router, Switch } from 'react-router-dom';
import RouteForTemplates from './Components/routes/route-for-templates';
// import { LoaderPage } from './Components/loaders/loader';

// HOOKS
// import useApi from './Hooks/useApi';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateGlobalStoreGlobal,
  // updateGlobalisLoaded,
  updateGlobalStoreRoutes,
  updateGlobalScrollLock,
  updateGlobalStoreLang,
  updateIsMobile,
  updateIsTablet,
  updateIsTabletWide,
  updateIsDesktop
} from './Store/action';

// TPL PARTS
// import Header from './Components/header/header';
// import Footer from './Components/footer/footer';

const App = () => {
  // Global apis
	let dispatch = useDispatch();
  // let globalStore = useSelector(state => state.global);
  
  // API dynamiques ou via des JSON
	// let [ dataGlobalAPI, dataGlobalIsLoaded ] = useApi({name:'global'});
  // let [ dataRoutesAPI, routesIsLoaded ] = useApi({name:'routes'});
  let dataGlobalAPI = require('./api/global.json');
  let dataRoutesAPI = require('./api/route.json');

  // let { routes, isLoaded } = globalStore;
  // let dataRoutes = routes;
  
  let page = useSelector(state => state.page).page;
  
  let { mobile, tablet, tabletwide, desktop } = useSelector(state => state.breakpoint);

  // INIT SCROLL LOCK REDUCER
	useEffect(()=>{
    dispatch(updateGlobalScrollLock(false));
  },[dispatch]);

 // STORE ADD GLOBAL API
	useEffect(()=>{
		dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
	},[ dispatch, dataGlobalAPI ])
  
  // STORE ADD ROUTES API
	useEffect(()=>{
		dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
	},[ dispatch, dataRoutesAPI ])

  // STORE SET IS LOADED
	// useEffect(()=>{
	// 	dispatch(updateGlobalisLoaded(routesIsLoaded && dataGlobalIsLoaded))	
  // },[dispatch, routesIsLoaded, dataGlobalIsLoaded])

  // DISPATCH LANGUAGE FROM URL
	useEffect(() => {
		let langArray = window.location.href.split('/');
		langArray[3] === 'en' && dispatch(updateGlobalStoreLang('en'));
		langArray[3] === 'fr' && dispatch(updateGlobalStoreLang('fr'));
	}, [dispatch])

	// DISPATCH WINDOW SIZE ON RESIZE
	useEffect(() => {
		const checkDevice = () => {
			if (window.matchMedia('(max-width:767px)').matches && !mobile) {
				dispatch(updateIsMobile(true));
				dispatch(updateIsTablet(false));
				dispatch(updateIsTabletWide(false));
				dispatch(updateIsDesktop(false));
			}
			if (window.matchMedia('(min-width:768px) and (max-width:1023px)').matches && !tablet) {
				dispatch(updateIsMobile(false));
				dispatch(updateIsTablet(true));
				dispatch(updateIsTabletWide(false));
				dispatch(updateIsDesktop(false))
			}
			if (window.matchMedia('(min-width:1024px) and (max-width: 1099px)').matches && !tabletwide) {
				dispatch(updateIsMobile(false));
				dispatch(updateIsTablet(false));
				dispatch(updateIsTabletWide(true));
				dispatch(updateIsDesktop(false))
			}
			if (window.matchMedia('(min-width:1100px)').matches && !desktop) {
				dispatch(updateIsMobile(false));
				dispatch(updateIsTablet(false));
				dispatch(updateIsTabletWide(false));
				dispatch(updateIsDesktop(true))
			}	
		}

		checkDevice();
		
		window.addEventListener('resize', checkDevice);

		// clean listener
		return () => {
			window.removeEventListener('resize', checkDevice);
		}
	}, [dispatch, desktop, tabletwide, mobile, tablet]);
  
  return (
    <div className={`app ${page}`}>

      {/* <LoaderPage load={isLoaded}/> */}

      <Router>

        { // isLoaded ? (
          
          <ScrollToTop>

            <Switch>
              {dataRoutesAPI && (
                dataRoutesAPI.map((route) => {
                  return <RouteForTemplates key={route._uid} {...route} datas={ {} } />;
                })
              )}
            </Switch>


          </ScrollToTop>

        // ) : <div style={{height: '100vh'}}></div>
        }

      </Router>

    </div>
  );
}

export default App;
