import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';

const Template404 = () => {

	let dispatch = useDispatch();
	useEffect(()=>{
		dispatch(updateGlobalStorePage('page-404'));
	},[dispatch]);

	return(
		<>
			<div className="headerPage">
				<div className="layout">
					<div className="headerPage_inner">
						<h1 className="headerPage_title">404 Error.</h1>
						<div className="text">
							<br />
							<p>The page you're looking for doesn't exist.</p>
							<p>
								<span className="icon-arrow-right"></span>
								<span> </span>
								<a href="/en/">
									Go back to homepage
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
};

export default Template404;