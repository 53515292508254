import React, { useState } from "react";

// import YouTube from "react-youtube";
import ReactPlayer from "react-player";

import { useMediaQuery } from "react-responsive";

const Thumbnail = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const thumbnails = [
    {
      url: require("../../assets/images/reasons/raison-pac.jpg"),
      alt: "Les PAC les plus silencieuses du marché",
      video: require("../../assets/videos/gamme-PAC.mp4"),
    },
    {
      url: require("../../assets/images/reasons/raison-fabrication-fr.jpg"),
      alt: "Fabrication 100% française",
      video: require("../../assets/videos/fabrication-francaise.mp4"),
    },
    {
      url: require("../../assets/images/reasons/raison-expertise.jpg"),
      alt: "Plus de 110 ans d’expertise",
      video: "",
    },
  ];
  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="m-thumbnail__section">
      <div className="layout">
        <h2 className="section-title">3 raisons de choisir Saunier Duval</h2>

        <div className="row m-thumbnail__row">
          <div className="m-thumbnail col-third">
            <div className="m-thumbnail__inner has-link">
              {isTabletOrMobile && (
                <div>
                  {modal1 ? (
                    <div className="m-thumbnail__image-wrapper js-open-video">

                      <ReactPlayer
                        url="https://www.youtube-nocookie.com/embed/BRBVrSD82-o"
                        className="m-text-video__modal-relative-blockvideo-video"
                        config={{ youtube: { playerVars: { autoplay: 1 } } }}
                        playing={true}
                      />
                    </div>
                  ) : (
                    <div
                      className="m-thumbnail__image-wrapper js-open-video"
                      onClick={() => {
                        setModal1(true);
                      }}
                    >
                      <img
                        className="m-thumbnail__image"
                        src={thumbnails[0].url}
                        alt={thumbnails[0].alt}
                      />

                      <span className="video-play"></span>
                    </div>
                  )}
                </div>
              )}
              {isDesktopOrLaptop && (
                <div
                  className="m-thumbnail__image-wrapper js-open-video"
                  onClick={() => {
                    setModal1(true);
                  }}
                >
                  <img
                    className="m-thumbnail__image"
                    src={thumbnails[0].url}
                    alt={thumbnails[0].alt}
                  />

                  <span className="video-play"></span>
                </div>
              )}
              <div className="m-thumbnail__content">
                <h3 className="m-thumbnail__title">
                  Les PAC les plus silencieuses du marché
                </h3>
                <p>
                  Pour améliorer votre confort de vie, l’acoustique est un point
                  clé. C’est pourquoi Saunier Duval crée des pompes à chaleur
                  jusqu’à 8 fois plus silencieuses que les produits standards du
                  marché. Performantes mais en silence !
                </p>
                <a
                  href="https://www.saunierduval.fr/particulier/aide-conseils/guides-pompes-a-chaleur/quelle-pompe-a-chaleur-chosir/#image___text_3"
                  target="_blank"
                  className="link has-arrow"
                >
                  En savoir plus
                </a>
              </div>
            </div>
          </div>

          <div className="m-thumbnail col-third">
            <div className="m-thumbnail__inner has-link">
              {isTabletOrMobile && (
                <div>
                  {modal2 ? (
                    <div className="m-thumbnail__image-wrapper js-open-video">

                      <ReactPlayer
                        url="https://www.youtube-nocookie.com/embed/emQt3rFndrM"
                        className="m-text-video__modal-relative-blockvideo-video"
                        config={{ youtube: { playerVars: { autoplay: 1 } } }}
                        playing={true}
                      />
                    </div>
                  ) : (
                    <div
                      className="m-thumbnail__image-wrapper js-open-video"
                      onClick={() => {
                        setModal2(true);
                      }}
                    >
                      <img
                        className="m-thumbnail__image"
                        src={thumbnails[1].url}
                        alt={thumbnails[1].alt}
                      />

                      <span className="video-play"></span>
                    </div>
                  )}
                </div>
              )}
              {isDesktopOrLaptop && (
                <div
                  className="m-thumbnail__image-wrapper js-open-video"
                  onClick={() => {
                    setModal2(true);
                  }}
                >
                  <img
                    className="m-thumbnail__image"
                    src={thumbnails[1].url}
                    alt={thumbnails[1].alt}
                  />

                  <span className="video-play"></span>
                </div>
              )}

              <div className="m-thumbnail__content">
                <h3 className="m-thumbnail__title">
                  Fabrication 100 % française
                </h3>
                <p>
                  Choisir Saunier Duval, c’est aussi choisir des pompes à
                  chaleur fabriquées en France. Nos pompes à chaleur sont
                  conçues, fabriquées et testées dans notre usine à Nantes. Un
                  engagement fort de notre marque mais aussi un gage de qualité
                  et de fiabilité de nos produits. Notre usine de Nantes est
                  ainsi devenue le plus grand site français de production
                  d’unités extérieures de pompes à chaleur résidentielles avec
                  plus de <span className="nowrap">35 000</span> unités
                  produites par an !
                </p>
                <a
                  href="https://www.saunierduval.fr/particulier/nous-faire-confiance/fabricant-francais/fabrique-en-france/"
                  target="_blank"
                  className="link has-arrow"
                >
                  En savoir plus
                </a>
              </div>
            </div>
          </div>

          <div className="m-thumbnail col-third">
            <div className="m-thumbnail__inner has-link">
              <div className="m-thumbnail__image-wrapper">
                <a
                  href="https://www.saunierduval.fr/particulier/nous-faire-confiance/saunier-duval-a-110-ans/"
                  target="_blank"
                  className="has-link"
                >
                  <img
                    className="m-thumbnail__image"
                    src={thumbnails[2].url}
                    alt={thumbnails[2].alt}
                  />
                </a>
              </div>
              <div className="m-thumbnail__content">
                <h3 className="m-thumbnail__title">
                  Plus de 110 ans d’expertise
                </h3>
                <p>
                  Depuis plus de 110 ans, chez Saunier Duval, nous proposons des
                  solutions de chauffage performantes, simples d’utilisation et
                  fabriquées en France.
                  <br /> Depuis plus de 110 ans, nous avons tissé un réseau de
                  partenaires d’excellence pour accompagner nos clients dans
                  l’installation et l’entretien de nos produits.
                  <br /> Depuis plus de 110 ans, nous mettons notre savoir-faire
                  et notre capacité d'innovation au service du confort de nos
                  clients et désormais de leur transition énergétique.
                </p>
                <a
                  href="https://www.saunierduval.fr/particulier/nous-faire-confiance/saunier-duval-a-110-ans/"
                  target="_blank"
                  className="link has-arrow"
                >
                  En savoir plus
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDesktopOrLaptop && (
        <div>
          {modal1 ? (
            <div
              className="m-text-video__modal"
              onClick={() => {
                setModal1(false);
              }}
            >
              <div className="m-text-video__modal-relative">
                <div className="m-text-video__modal-relative-blockvideo">

                  <ReactPlayer
                    url="https://www.youtube-nocookie.com/embed/BRBVrSD82-o"
                    className="m-text-video__modal-relative-blockvideo-video"
                    config={{ youtube: { playerVars: { autoplay: 1 } } }}
                    playing={true}
                  />
                  <span
                    onClick={() => {
                      setModal1(false);
                    }}
                  ></span>
                </div>
              </div>
            </div>
          ) : null}

          {modal2 ? (
            <div
              className="m-text-video__modal"
              onClick={() => {
                setModal2(false);
              }}
            >
              <div className="m-text-video__modal-relative">
                <div className="m-text-video__modal-relative-blockvideo">

                  <ReactPlayer
                    url="https://www.youtube-nocookie.com/embed/emQt3rFndrM"
                    className="m-text-video__modal-relative-blockvideo-video"
                    config={{ youtube: { playerVars: { autoplay: 1 } } }}
                    playing={true}
                  />
                  <span
                    onClick={() => {
                      setModal2(false);
                    }}
                  ></span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default Thumbnail;
