import React, {useEffect} from "react";
// import useApi from '../Hooks/useApi';
import Metatags from '../Components/metas-tags/meta-tags';
import FullCarousel from "../Components/module/m-full-carousel";
import Arguments from "../Components/module/m-arguments";
import IconText from "../Components/module/m-icon-text";
import Reviews from "../Components/module/m-reviews";
import Thumbnail from "../Components/module/m-thumbnail";
import BestSellers from "../Components/module/m-best-sellers";
import TextVideo from "../Components/module/m-text-video";
import ThumbnailSlider from "../Components/module/m-thumbnail-slider";

import {useDispatch} from 'react-redux';
import {updateGlobalStorePage} from '../Store/action';

const TemplateHome = () => {

    // const [dataFetch, isLoaded] = useApi({name: 'home'})
	let dataFetch = require('../api/page.json');
    const {metas} = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('home'));
    }, [dispatch]); // isLoaded

    return (
        <>
            {metas && <Metatags {...metas} />}

            <div className="main-wrapper">
                <FullCarousel />
                <Arguments />
                <IconText />
                <Reviews />
                <Thumbnail />
                <BestSellers />
                <TextVideo />
                <ThumbnailSlider />
            </div>
            
        </>
    )
}

export default TemplateHome;