import React, { useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from '../Store/action';
// import { useLocation } from 'react-router-dom';
// import useApi from '../Hooks/useApi';

// Components
import ComponentByJSON from '../Components/routes/component-from-json';
import Metatags from '../Components/metas-tags/meta-tags';

const TemplateContentPage = () => { // { _uid }
	
	let dataFetch = require('../api/page.json');
	// const location = useLocation();
	// const [dataFetch, isLoaded] = useApi({name: 'content-page', slug: location.pathname, _uid});
	const { metas, flexibles_content } = dataFetch;

	let dispatch = useDispatch();
	useEffect(()=>{
		dispatch(updateGlobalStorePage('content-page'));
	},[dispatch]); // isLoaded

	return(
		<>
			<Metatags {...metas} />

			<div className="layout">
				<br />
				<br />
				<br />
				Page de contenu
				<br />
				<br />
				<br />
				<br />
			</div>

			{flexibles_content && flexibles_content.map((block) => ComponentByJSON(block))}

		</>
	)
}

export default TemplateContentPage;