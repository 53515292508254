import React, { useState } from "react";
// import YouTube from "react-youtube";
import ReactPlayer from 'react-player'

const Arguments = () => {

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  // Images
  const items = [
    {
      icon: require('../../assets/images/icons/ZZZ.svg'),
      url: require('../../assets/images/arguments/nuisances-sonores-desktop.png'),
      url_mobile: require('../../assets/images/arguments/nuisances-sonores-mobile.png'),
      alt: "Économisez jusqu'à 50%",
    },
    {
      icon: require('../../assets/images/icons/Maison.svg'),
      url: require('../../assets/images/arguments/neuf-ancien-desktop.png'),
      url_mobile: require('../../assets/images/arguments/neuf-ancien-mobile.png'),
      alt: "Réduisez vos émissions de CO2",
    },
    {
      icon: require('../../assets/images/icons/Outils.svg'),
      url: require('../../assets/images/arguments/installateur-desktop.png'),
      url_mobile: require('../../assets/images/arguments/installateur-mobile.png'),
      alt: "De l'eau chaude pour toute la famille",
    }
  ]

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  return (

    <div className="m-arguments__layout">
      <div className="layout_inner">
        <div className="m-arguments">
          <div className="m-arguments__header">
            {/* <p className="m-arguments__suptitle">Plus économiques, moins énergivores et apportant plus de confort :</p> */}
            <h2 className="m-arguments__main-title">Vous avez tout à gagner avec les pompes à chaleur Saunier Duval</h2>
          </div>

          <div>
            <div className="m-arguments-block m-arguments-block-1 row">
              <div className="m-arguments-block__content">
                <img src={items[0].icon} className="m-arguments-block__icon" alt="" />
                <h3 className="m-arguments-block__title">Avec les pompes à chaleur Saunier Duval, les nuisances sonores n’existent plus</h3>
                <p className="m-arguments-block__text">Les pompes à chaleur Saunier Duval comptent parmi les plus silencieuses du marché. Par exemple, avec 25 dB(A) à 5 mètres en mode silence, les modèles GeniaAir Split et GeniaAir Max sont jusqu’à 8 fois plus silencieux qu’une pompe à chaleur standard. Vous pouvez vivre et dormir en toute tranquillité !</p>
              </div>
              <div className="m-arguments-block__image">


                <img src={items[0].url} alt={items[0].alt} className="is-desktop" />
                <img src={items[0].url_mobile} alt={items[0].alt} className="is-mobile" />
                <button
                  type="button"
                  className="video-play"
                  onClick={(e) => {
                    setModal(true);
                  }}
                ></button>
              </div>
              {modal ? (
                <div
                  className="m-text-video__modal m-text-video__modal--type2 js-open-video"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  <div className="m-text-video__modal-relative">

                    <ReactPlayer
                      url="https://www.youtube-nocookie.com/embed/x8KaNMLJLsQ"
                      className="m-text-video__modal-relative-blockvideo-video"
                      config={{youtube:{playerVars:{autoplay:1}}}}
                      playing={true}
                    />

                    <span
                      onClick={() => {
                        setModal(false);
                      }}
                    ></span>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="m-arguments-block m-arguments-block-2 row">
              <div className="m-arguments-block__content">
                <img src={items[1].icon} className="m-arguments-block__icon" alt="" />
                <h3 className="m-arguments-block__title">Installez nos pompes à chaleur dans un logement neuf ou ancien</h3>
                <p className="m-arguments-block__text">Il n’y pas besoin d’avoir un logement neuf pour installer nos pompes à chaleur chez vous. Il suffit de suivre quelques règles simples pour coupler radiateurs et pompe à chaleur sans avoir à changer l’intégralité de votre système de chauffage.</p>
              </div>
              <div className="m-arguments-block__image">

                <img src={items[1].url} alt={items[1].alt} className="is-desktop" />
                <img src={items[1].url_mobile} alt={items[1].alt} className="is-mobile" />
                <button
                  type="button"
                  className="video-play"
                  onClick={(e) => {
                    setModal2(true);
                  }}
                ></button>
              </div>
              {modal2 ? (
                <div
                  className="m-text-video__modal m-text-video__modal--type2 js-open-video"
                  onClick={() => {
                    setModal2(false);
                  }}
                >
                  <div className="m-text-video__modal-relative">

                    <ReactPlayer
                      url="https://www.youtube-nocookie.com/embed/z8lwEkYDUKI"
                      className="m-text-video__modal-relative-blockvideo-video"
                      config={{youtube:{playerVars:{autoplay:1}}}}
                      playing={true}
                    />

                    <span
                      onClick={() => {
                        setModal2(false);
                      }}
                    ></span>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="m-arguments-block m-arguments-block-3 row">
              <div className="m-arguments-block__content">
                <img src={items[2].icon} className="m-arguments-block__icon" alt="" />
                <h3 className="m-arguments-block__title">Trouver un installateur de qualité ? Rien de plus simple</h3>
                <p className="m-arguments-block__text">Nous pouvons vous aider à trouver un professionnel compétent proche de chez vous. Nous avons constitué un réseau de partenaires chauffagistes disposant des qualifications indispensables et s’engageant à travailler dans le respect le plus strict de la législation et des normes de qualité. Ils possèdent, en outre, une parfaite connaissance de notre gamme de pompes à chaleur et des matériels associés. Installez votre pompe à chaleur les yeux fermés !</p>
              </div>
              <div className="m-arguments-block__image">

                <img src={items[2].url} alt={items[2].alt} className="is-desktop" />
                <img src={items[2].url_mobile} alt={items[2].alt} className="is-mobile" />
                <button
                  type="button"
                  className="video-play"
                  onClick={(e) => {
                    setModal3(true);
                  }}
                ></button>
              </div>
              {modal3 ? (
                <div
                  className="m-text-video__modal m-text-video__modal--type2 js-open-video"
                  onClick={() => {
                    setModal3(false);
                  }}
                >
                  <div className="m-text-video__modal-relative">

                    <ReactPlayer
                      url="https://www.youtube-nocookie.com/embed/zaOxtNchvvg"
                      className="m-text-video__modal-relative-blockvideo-video"
                      config={{youtube:{playerVars:{autoplay:1}}}}
                      playing={true}
                    />

                    <span
                      onClick={() => {
                        setModal3(false);
                      }}
                    ></span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="text-center">
            <a href="https://www.saunierduval.fr/particulier/services/pompe-a-chaleur/devis-installation/" target="_blank" className="button">Demander un devis</a>
          </div>
          <div className="text-left">
            {/* <a href="https://www.saunierduval.fr/particulier/pompe-a-chaleur/conditions-1920995.html" target="_blank">*Voir le détail des calculs et hypothèses pris en compte</a> */}
          </div>
        </div>
      </div>

    </div>

  )
}
export default Arguments;