import React  from "react";


const IconText = () => {

  // Images
  const icons = [
    require('../../assets/images/icons/icon-aides.svg'),
    require('../../assets/images/icons/icon-partenaires.svg'),
  ]

	return(

    <div className="m-icon-text__section">
      <div className="layout">
        <h2 className="section-title">Avec Saunier Duval, une installation facile et rapide</h2>

        <div className="row">

          <div className="col-half m-icon-text">
            <div className="m-icon-text__icon">
              <img src={ icons[0] } alt="" width="60" height="60" className="icon-aides" />
            </div>
            <div className="m-icon-text__content">
              <h3 className="m-icon-text__title">Jusqu’à 15 000 € d’aides pour l’installation de votre pompe à chaleur</h3>
              <p>En remplaçant votre appareil de chauffage par une pompe à chaleur, vous faites un pas de plus vers la transition énergétique. Vous pouvez bénéficier d’aides pour son installation : Ma Prime Renov, aides de l’ANAH, éco-prêt à taux zéro... Découvrez les aides auxquelles vous pouvez prétendre grâce à notre simulateur.</p>
              <a href="https://www.saunierduval.fr/particulier/aide-conseils/aides-financieres/simulation-aides-financieres/" target="_blank" className="button">Simuler votre éligibilité</a>
            </div>
          </div>
          <div className="col-half m-icon-text">
            <div className="m-icon-text__icon">
              <img src={ icons[1] } alt="" width="70" height="70" />
            </div>
            <div className="m-icon-text__content">
              <h3 className="m-icon-text__title">Une installation de qualité grâce à notre réseau de partenaires engagés</h3>
              <p>Bénéficiez de notre réseau exceptionnel de 2 000 installateurs pour votre pompe à chaleur. Tous nos partenaires s’engagent à vous offrir un service de qualité, de l’installation de nos matériels aux conseils en optimisation énergétique de votre logement.</p>
              <a href="https://www.saunierduval.fr/particulier/services/trouver-un-pro/" target="_blank" className="button">Trouver un pro</a>
            </div>
          </div>

        </div>
      </div>
    </div>

	)
}
export default IconText;