import React, { useState } from "react";

import ReactPlayer from "react-player";
// import YouTube from "react-youtube";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, A11y, EffectFade } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-fade/effect-fade.scss";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y, EffectFade]);

const FullCarousel = () => {
  const [modal, setModal] = useState(false);

  // Images
  const banners = [
    {
      url: require("../../assets/images/banners/louisXIV-banner-desktop.png"),
      url_mobile: require("../../assets/images/banners/louisXIV-banner-desktop.png"),
      // video: require("../../assets/videos/saunier-duval-main.mp4"),
    },
    {
      // url: require("../../assets/images/banners/b2-economies-desktop.jpg"),
      // url_mobile: require("../../assets/images/banners/b2-economies-mobile.jpg"),
      // alt: "Économiser d'argent",
    },
    {
      // url: require("../../assets/images/banners/b3-co2-desktop.jpg"),
      // url_mobile: require("../../assets/images/banners/b3-co2-mobile.jpg"),
      // alt: "Émet moins de CO2",
    },
    {
      // url: require("../../assets/images/banners/b4-eau-chaud-desktop.jpg"),
      // url_mobile: require("../../assets/images/banners/b4-eau-chaud-mobile.jpg"),
      // alt: "Fournit de l'eau chaude pour toute la famille",
    },
  ];
  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <>
      <div className="layout m-full-carousel__layout">
        <div className="m-full-carousel swiper">
          {modal ? (
            <div
              className="m-text-video__modal"
              onClick={() => {
                setModal(false);
              }}
            >
              <div className="m-text-video__modal-relative">

                <ReactPlayer
                  url="https://www.youtube-nocookie.com/embed/AkRGdguWb7Y"
                  className="m-text-video__modal-relative-blockvideo-video"
                  config={{youtube:{playerVars:{autoplay:1}}}}
                  playing={true}
                />

                <span
                  onClick={() => {
                    setModal(false);
                  }}
                ></span>
              </div>
            </div>
          ) : null}
          <Swiper
            effect="fade"
            slidesPerView={1}
            allowTouchMove={1}
            loop={1}
            speed={800}
            fadeEffect={{ crossFade: true }}
            navigation
            pagination={{ clickable: true }}
            breakpoints={{
              768: {
                allowTouchMove: false,
              },
            }}
            onSlideChange={() => {}}
          >
            <SwiperSlide>
              <div className="m-full-carousel__video bg-video">
                <span
                  className="m-full-carousel__thumbnail is-desktop"
                  style={{ backgroundImage: `url(${banners[0].url})` }}
                ></span>
                <span
                  className="m-full-carousel__thumbnail is-mobile"
                  style={{ backgroundImage: `url(${banners[0].url_mobile})` }}
                ></span>
                <button
                  type="button"
                  className="video-play"
                  onClick={(e) => {
                    setModal(true);
                  }}
                ></button>
              </div>
              <div className="m-full-carousel__content">
                <h2 className="m-full-carousel__title">
                  Saunier Duval invente les pompes à chaleur que les Français
                  veulent !
                </h2>
                <p className="m-full-carousel__text">
                Découvrez Florian et Arnaud, notre duo d’ingénieurs qui imagine les pompes à chaleur fabriquées en France répondant à toutes vos attentes ! Silencieuses, adaptées à tous les logements neufs ou anciens et installées par des professionnels compétents.
                </p>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <img
                src={banners[1].url}
                alt={banners[1].alt}
                className="m-full-carousel__image is-desktop"
              />
              <img
                src={banners[1].url_mobile}
                alt={banners[1].alt}
                className="m-full-carousel__image is-mobile"
              />
              <div className="m-full-carousel__content">
                <h2 className="m-full-carousel__title">
                  Je veux une pompe à chaleur qui me fasse économiser de
                  l’argent
                </h2>
                <p className="m-full-carousel__text">
                  Jusqu’à 50 % d’économies sur votre facture de chauffage.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={banners[2].url}
                alt={banners[2].alt}
                className="m-full-carousel__image is-desktop"
              />
              <img
                src={banners[2].url_mobile}
                alt={banners[2].alt}
                className="m-full-carousel__image is-mobile"
              />
              <div className="m-full-carousel__content">
                <h2 className="m-full-carousel__title">
                  Je veux une pompe à chaleur qui émette moins de CO2
                </h2>
                <p className="m-full-carousel__text">
                  Jusqu’à 80 % de C02 en moins !
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={banners[3].url}
                alt={banners[3].alt}
                className="m-full-carousel__image is-desktop"
              />
              <img
                src={banners[3].url_mobile}
                alt={banners[3].alt}
                className="m-full-carousel__image is-mobile"
              />
              <div className="m-full-carousel__content">
                <h2 className="m-full-carousel__title">
                  Je veux une pompe à chaleur qui fournit de l’eau chaude pour
                  toute la famille !
                </h2>
                <p className="m-full-carousel__text">
                  Jusqu’à 5 douches en même temps !
                </p>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default FullCarousel;
