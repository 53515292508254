import React, { useState } from "react";

// import YouTube from "react-youtube";
import ReactPlayer from "react-player";

import { useMediaQuery } from "react-responsive";

const TextVideo = () => {
  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 992px)",
  });
  // Images
  const video_thumbnail = require("../../assets/images/pompes-a-chaleur.jpg");

  const [modal, setModal] = useState(false);

  // playvideo => { }

  return (
    <>
      <div className="m-text-video__section">
        {isDesktopOrLaptop && (
          <div>
            {modal ? (
              <div
                className="m-text-video__modal"
                onClick={() => {
                  setModal(false);
                }}
              >
                <div className="m-text-video__modal-relative">
                  <div className="m-text-video__modal-relative-blockvideo">

                    <ReactPlayer
                      url="https://www.youtube-nocookie.com/embed/y8Oc0c2EDOw"
                      className="m-text-video__modal-relative-blockvideo-video"
                      config={{youtube:{playerVars:{autoplay:1}}}}
                      playing={true}
                    />
                  </div>

                  <span
                    onClick={() => {
                      setModal(false);
                    }}
                  ></span>
                </div>
              </div>
            ) : null}
          </div>
        )}

        <div className="layout_inner">
          <h2 className="section-title">
            Tout savoir sur les pompes à chaleur
          </h2>

          <div className="m-text-video-row">
            <div className="m-text-video__content">
              <div className="m-text-video__content-inner">
                <h3 className="title">
                  Les pompes à chaleur, comment ça marche ?
                </h3>
                <p>
                  Les pompes à chaleur aérothermiques utilisent une ressource
                  renouvelable et inépuisable pour se chauffer ou se
                  rafraîchir : l’air extérieur.
                  <br />
                  {/* Mais comment cela fonctionne exactement ? 
              On vous explique tout en vidéo. */}
                </p>
              </div>
            </div>
            {isTabletOrMobile && (
              <>
                {modal ? (
                  <div className="m-text-video__modal-relative-blockvideo">

                    <ReactPlayer
                      url="https://www.youtube-nocookie.com/embed/y8Oc0c2EDOw"
                      className="m-text-video__modal-relative-blockvideo-video"
                      config={{youtube:{playerVars:{autoplay:1}}}}
                      playing={true}
                    />
                  </div>
                ) : (
                  <span
                    className="m-text-video__thumbnail"
                    style={{ backgroundImage: `url(${video_thumbnail})` }}
                    onClick={() => {
                      // playVideo(e);
                      // setIsPlayed(!isplayed);
                      setModal(true);
                      //videoplay
                    }}
                  >
                    <button type="button" className="video-play"></button>
                  </span>
                )}
              </>
            )}

            {isDesktopOrLaptop && (
              <span
                className="m-text-video__thumbnail"
                style={{ backgroundImage: `url(${video_thumbnail})` }}
                onClick={() => {
                  setModal(true);
                }}
              >
                <button type="button" className="video-play"></button>
              </span>
            )}

            {/* <div className="popup__video-wrapper video-wrapper">
            <iframe
              height="600px"
              width="100%"
              src="https://www.youtube.com/embed/kisxHp8Zoag"
              title="YouTube video player"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default TextVideo;
