import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from 'react-router-dom';

// templates
import Template404 from '../../Templates/Template404';
import TemplateHome from '../../Templates/TemplateHome';
import TemplateContentPage from '../../Templates/TemplateContentPage';
import TemplateNews from '../../Templates/TemplateNews';
import { useSelector } from 'react-redux';

/**
 * @description à modifier suivant les projets
 */
const DynamicTemplate = {
	'Template 404': Template404,
	'Template Home': TemplateHome,
	'Template Content Page': TemplateContentPage,
	'Template News': TemplateNews,
};

/**
 * @component 
 * @description crer des routes en fonction des données JSON et utilise la correspondance nom côté back // nom component côté front
 * @param {object} route data des routes 
 */
function RouteForTemplates(route) {
	let lang = useSelector(state => state.global).lang;
	let history = useHistory();
	let search = useLocation().search; // si recherche type http://monurl.com/recherche?=texte
	let hash = useLocation().hash; // si recherche avec # dans l'url

	useEffect(
		() => {
			switch (lang) {
				case 'fr':
					history.replace({ pathname: route.path_fr, search, hash });
					break;
				case 'en':
					history.replace({ pathname: route.path_en, search, hash });
					break;
				default:
					break;
			}
		}, [lang]); // eslint-disable-line

	return (
		<Route path={route['path_' + lang]} name={route.component} exact={route.exact} render={() => 
			React.createElement(DynamicTemplate[route.component], {
				key: route._uid,
				...route.datas,
				_uid: route._uid
			})
		} />
	);
}
export default RouteForTemplates;